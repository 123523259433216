.DetailedContract {
  padding: 34px;
  font-size: 11px;
  .col-10 {
    width: 10%;
    display: inline-block;
    vertical-align: top;
  }
  .col-20 {
    width: 20%;
    display: inline-block;
    vertical-align: top;
  }
  .col-30 {
    width: 30%;
    display: inline-block;
    vertical-align: top;
  }
  .col-40 {
    width: 40%;
    display: inline-block;
    vertical-align: top;
  }
  .col-50 {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    @media only screen and (max-width: 600px) {
      width: 100%;
      display: block;
    }
  }
  .col-60 {
    width: 60%;
    display: inline-block;
    vertical-align: top;
  }
  .col-70 {
    width: 70%;
    display: inline-block;
    vertical-align: top;
  }
  .col-80 {
    width: 80%;
    display: inline-block;
    vertical-align: top;
  }
  .col-90 {
    width: 90%;
    display: inline-block;
    vertical-align: top;
  }
  .col-100 {
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  .DetailedContract__row {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .DetailedContract__table {
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: none;
    border-radius: 0.28571429rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    border-collapse: separate;
    border-spacing: 0;
    font-size: 1em;
    thead tr:first-child > th:first-child {
      border-radius: 0.28571429rem 0 0 0;
    }
    thead {
      box-shadow: none;
      display: table-row-group;
    }
    thead th {
      cursor: auto;
      background: #f9fafb;
      text-align: inherit;
      color: rgba(0, 0, 0, 0.87);
      padding: 0.92857143em 0.78571429em;
      vertical-align: inherit;
      font-weight: 700;
      font-size: 14px;
      text-transform: none;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    }
    // tbody tr:first-child td {
    //   border-top: 1px solid rgba(34, 36, 38, 0.1);
    // }
    tbody tr td {
      border-top: 1px solid rgba(34, 36, 38, 0.1);
      padding: 4px 8px;
      vertical-align: top;
    }
  }

  .signatures {
    width: 100%;
    @media only screen and (min-width: 600px) {
      margin: auto;
      max-width: 290px;
    }
  }

  @media print {
    .DetailedContract {
      border: 0;
      -webkit-print-color-adjust: exact;
      table {
        page-break-after: auto;
      }
      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }
      td {
        page-break-inside: avoid;
        page-break-after: auto;
      }
      thead {
        display: table-header-group;
      }
      tfoot {
        display: table-footer-group;
      }
    }
  }
}
