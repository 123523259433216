.Forecasting {
  padding-top: 30px !important;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  input {
    margin-bottom: 3px;
    padding: 3px;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);
  }
}
