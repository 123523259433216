.Browser2 {
  .browser-initials-header {
    padding: 0px;
    padding-left: 5px;
    justify-content: center;
  }
  .ag-row-selected {
    background-color: rgba(196, 223, 216, 0.7);
  }
  .ag-row-hover {
    /* putting in !important so it overrides the theme's styling as it hovers the row also */
    background-color: #c4dfd8 !important;
  }
}
