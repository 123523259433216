.TimesheetTable {
  .ag-header-group-cell-label {
    justify-content: center;
  }
  .ag-header-cell-label {
    justify-content: center;
  }
  .ag-cell {
    border-right: 1px solid #aaa !important;
  }
  .ag-row-hover {
    /* putting in !important so it overrides the theme's styling as it hovers the row also */
    background-color: #dfdfff !important;
  }

  // .ag-column-hover {
  //   background-color: #dfffdf;
  // }
}

@media print {
  // body * {
  //   visibility: hidden;
  // }
  #myGrid,
  #myGrid * {
    visibility: visible;
  }
  #myGrid {
    position: absolute;
    left: 0;
    top: 0;
  }
}
