.contract-requirements {
  .concent-of-owner {
    padding: 2rem 0 1rem;
    font-size: 10px;
    text-align: justify;
  }

  .requirements {
    font-size: 11px;
  }
}
