.EditInspectionModalNew {
  label {
    color: gray !important;
    // font-weight: 400 !important;
  }

  .disabled {
    opacity: 0.9 !important;

    label {
      opacity: 0.9 !important;
    }
  }
}
