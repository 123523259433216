.ClientInvoicing {
  .border-left {
    border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important;
  }
  .pay-app-total-input input {
    color: blue;
    // border: none;
  }

  .pay-app-total-input .label {
    color: blue;
    // background-color: #fff;
  }
}
