.IncentivePayoutCertificate {
  margin: 0;
  padding: 0;
  color: white;
  .cer {
    position: relative;
    width: 11in;
    height: 8.2in;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    text-align: center;
    .bonus-certificate-image {
      margin-left: 0.7in;
      margin-right: 0;
      margin-top: 0.3in;
      margin-bottom: 0.455in;
      width: 9.56in;
    }
  }
  td {
    text-align: center;
    color: black;
    border: 1px solid gray;
  }
  th {
    text-align: center;
    color: black;
    font-weight: bold;
    border: 1px solid gray;
  }

  @media print {
    .cer {
      page-break-after: always;
    }
  }
}
