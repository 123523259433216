.totals-summary {
  font-size: 11px;

  th:first-child {
    width: 75%;
  }

  th {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    background-color: rgba(0, 0, 0, 0.03);
  }

  tr:last-child th {
    border-bottom: none;
  }

  td {
    font-weight: 700;

    &.price {
      color: rgb(254, 80, 0);
    }
  }

  .change-order-summary {
    thead {
      th:first-child {
        border-top: none !important;
        border-left: none !important;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        background-color: rgba(0, 0, 0, 0.03);
      }
      th:last-child {
        border-left: 1px solid rgba(34, 36, 38, 0.15);
      }
    }
    tbody tr:first-child > td {
      border-top: 1px solid rgba(34, 36, 38, 0.15);
    }
  }
}
