.SidebarContainer {
  .menu {
    .item {
      text-align: left !important;
      & > .icon:not(.dropdown) {
        display: inline-block;
      }
    }
  }
}

.pushable:not(body) {
  transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}
