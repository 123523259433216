.heading {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  > div {
    &:first-child,
    &:last-child {
      width: 30%;
    }
    &:last-child {
      text-align: right;
    }
  }
}
