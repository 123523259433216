.signatures {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0;

  p.error {
    color: red;
    font-size: 12px;
    margin-top: 0.5rem !important;
  }

  .signature {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    background-color: rgba(0, 0, 0, 0.05);
    img {
      width: 100%;
    }
  }

  h3 {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: 7px 0;
    margin: 0;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }

  h5 {
    font-size: "1.28571429em";
    padding: 8px 10px;
    small {
      display: block;
      break-inside: avoid;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .client,
  .nexus {
    width: 290px;
    break-inside: avoid;
    border-radius: 5px;
    border: 1px solid rgba(34, 36, 38, 0.1);
  }
}
