.Condition label {
  color: gray !important;
}

.Condition div.toast-container {
  width: 500px !important;
}

.Condition .disabled {
  opacity: 1 !important;

  label {
    opacity: 1 !important;
  }
}

.Condition {
  input {
    min-width: 75px;
    padding-right: 0 !important;
  }
  .input-total input {
    background: #fcf6ed !important;
  }

  .input-total-select div.disabled {
    background-color: #fcf6ed !important;
  }

  .N5SubInput input {
    color: orangered !important;
    font-weight: bold;
  }

  .dropdown-contract .disabled {
    background-color: #fcf6ed;
  }

  // input[type='number']::-webkit-inner-spin-button,
  // input[type='number']::-webkit-outer-spin-button {
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   appearance: none;
  //   margin: 0;
  // }
  // input[type='number'] {
  //   -moz-appearance: textfield;
  // }
}
