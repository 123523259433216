.TopMenu {
  .item {
    .logo {
      width: 113px;
    }
    & > i.icon.icon-bars {
      margin: 0;
    }
  }
}
