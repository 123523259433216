.inclusions {
  .category {
    font-size: 12px;
    font-weight: bold;
    background-color: lightyellow !important;
  }

  .sub-category {
    th {
      color: gray !important;
      font-weight: bold !important;
    }
  }

  .inclusion {
    .total {
      color: rgb(254, 80, 0);
      font-weight: bold;
      text-align: right;
    }
  }

  .footer {
    th {
      border-top: 1px solid rgba(34, 36, 38, 0.15);
      background-color: white !important;
      padding-top: 40px !important;
      padding-bottom: 10px !important;
      padding-right: 7.2px !important;
      padding-left: 7.2px !important;
      font-size: 22px !important;
      font-weight: bold;
    }
    .total {
      color: rgb(254, 80, 0);
      font-weight: bold;
      text-align: right;
    }
  }

  .category,
  .sub-category,
  .inclusion {
    td,
    th {
      padding: 4px 8px;
      box-shadow: none;
      font-size: 12px;
      vertical-align: top;
    }
  }
}
