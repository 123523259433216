html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  margin-top: 0;
  height: 100vh; }

.Login {
  background-color: black; }

.SidebarContainer .menu .item {
  text-align: left !important; }
  .SidebarContainer .menu .item > .icon:not(.dropdown) {
    display: inline-block; }

.pushable:not(body) {
  transform: none; }

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed; }


.TopMenu .item .logo {
  width: 113px; }

.TopMenu .item > i.icon.icon-bars {
  margin: 0; }

.ProjectInfo label {
  color: gray !important;
  font-weight: 400 !important; }

.ProjectInfo .disabled {
  opacity: 0.9 !important; }
  .ProjectInfo .disabled label {
    opacity: 0.9 !important; }

.ProjectInfo .projectid input {
  padding-left: 12px !important; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__input {
  width: 240px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 37px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px; }

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd; }

.LabelList {
  margin-top: 12px; }
  .LabelList .Label {
    margin-bottom: 3px; }

.Forecasting {
  padding-top: 30px !important; }
  .Forecasting input[type='number']::-webkit-inner-spin-button,
  .Forecasting input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; }
  .Forecasting input {
    margin-bottom: 3px;
    padding: 3px;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15); }

.ui.buttons .or:before {
  line-height: 1.7em; }

.ClientContractTracking {
  overflow: auto; }

.ClientContractDetail .border-left {
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important; }

.SubContractTracking {
  overflow: auto; }

.SubContractDetail .border-left {
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important; }

.ClientInvoicing .border-left {
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1) !important; }

.ClientInvoicing .pay-app-total-input input {
  color: blue; }

.ClientInvoicing .pay-app-total-input .label {
  color: blue; }

.Tracking .border-left {
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important; }

.showv1 {
  position: absolute;
  left: 1rem;
  top: 8px;
}

.InclusionTable .border-left {
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important; }

.InclusionTable .footer-text {
  color: blue !important;
  font-size: 12px;
  font-weight: bold !important; }

.ProjectTabs > div {
  max-height: calc(100vh - 124px); }

.Inclusions {
  padding-top: 20px; }

.Condition label {
  color: gray !important; }

.Condition div.toast-container {
  width: 500px !important; }

.Condition .disabled {
  opacity: 1 !important; }
  .Condition .disabled label {
    opacity: 1 !important; }

.Condition input {
  min-width: 75px;
  padding-right: 0 !important; }

.Condition .input-total input {
  background: #fcf6ed !important; }

.Condition .input-total-select div.disabled {
  background-color: #fcf6ed !important; }

.Condition .N5SubInput input {
  color: orangered !important;
  font-weight: bold; }

.Condition .dropdown-contract .disabled {
  background-color: #fcf6ed; }

.CRM .disable-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by any browser but < IE9 */ }

.segment-devextreme-datagrid {
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
  background-color: #2a2a2a !important; }




.SurveyList span.ag-header-icon.ag-header-label-icon.ag-sort-order {
  display: none; }

.TeamMemberGoals .ag-theme-balham .ag-ltr .ag-cell {
  border-right: 1px solid #d9dcde; }

.NewConditionApprovalTable .cell-wrap-text {
  white-space: normal !important; }

.TimesheetTable .ag-header-group-cell-label {
  justify-content: center; }

.TimesheetTable .ag-header-cell-label {
  justify-content: center; }

.TimesheetTable .ag-cell {
  border-right: 1px solid #aaa !important; }

.TimesheetTable .ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: #dfdfff !important; }

@media print {
  #myGrid,
  #myGrid * {
    visibility: visible; }
  #myGrid {
    position: absolute;
    left: 0;
    top: 0; } }

.WeeklyTimesheet div[role="button"] {
  display: inline-block; }

.EditInspectionModalNew label {
  color: gray !important; }

.EditInspectionModalNew .disabled {
  opacity: 0.9 !important; }
  .EditInspectionModalNew .disabled label {
    opacity: 0.9 !important; }

.EditCourseModal label {
  color: gray !important; }

.EditCourseModal .disabled {
  opacity: 0.9 !important; }
  .EditCourseModal .disabled label {
    opacity: 1 !important; }

.Browser2 .browser-initials-header {
  padding: 0px;
  padding-left: 5px;
  justify-content: center; }

.Browser2 .ag-row-selected {
  background-color: rgba(196, 223, 216, 0.7); }

.Browser2 .ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: #c4dfd8 !important; }

.span-project-stage-advance:hover {
  cursor: pointer; }

.MonthInput:hover {
  cursor: pointer; }

.input__forecast {
  width: 30px;
  height: 21px; }

.LabelList {
  margin-top: 12px; }
  .LabelList .Label {
    margin-bottom: 3px; }

.Browser2 .browser-initials-header {
  padding: 0px;
  padding-left: 5px;
  justify-content: center; }

.Browser2 .ag-row-selected {
  background-color: rgba(196, 223, 216, 0.7); }

.Browser2 .ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: #c4dfd8 !important; }

.span-project-stage-advance:hover {
  cursor: pointer; }

.MonthInput:hover {
  cursor: pointer; }

.input__forecast {
  width: 30px;
  height: 21px; }

.ConditionsTable .cell-wrap-text {
  white-space: normal !important; }

.SummaryContract {
  border: 1px solid #b3b3b3;
  width: 1120px; }
  .SummaryContract body {
    -webkit-print-color-adjust: exact; }
  .SummaryContract .exclusions p {
    margin-bottom: 2px;
    width: 98%; }
  .SummaryContract .message .header {
    text-align: center; }

.contractSignatureCanvas {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  width: 100%;
  min-height: 200px; }

@media all {
  .SummaryContract {
    border: 0; }
    .SummaryContract body {
      -webkit-print-color-adjust: exact; } }

.ContractRequirements p {
  page-break-inside: avoid !important; }

.DetailedContract {
  padding: 34px;
  font-size: 11px; }
  .DetailedContract .col-10 {
    width: 10%;
    display: inline-block;
    vertical-align: top; }
  .DetailedContract .col-20 {
    width: 20%;
    display: inline-block;
    vertical-align: top; }
  .DetailedContract .col-30 {
    width: 30%;
    display: inline-block;
    vertical-align: top; }
  .DetailedContract .col-40 {
    width: 40%;
    display: inline-block;
    vertical-align: top; }
  .DetailedContract .col-50 {
    width: 50%;
    display: inline-block;
    vertical-align: top; }
    @media only screen and (max-width: 600px) {
      .DetailedContract .col-50 {
        width: 100%;
        display: block; } }
  .DetailedContract .col-60 {
    width: 60%;
    display: inline-block;
    vertical-align: top; }
  .DetailedContract .col-70 {
    width: 70%;
    display: inline-block;
    vertical-align: top; }
  .DetailedContract .col-80 {
    width: 80%;
    display: inline-block;
    vertical-align: top; }
  .DetailedContract .col-90 {
    width: 90%;
    display: inline-block;
    vertical-align: top; }
  .DetailedContract .col-100 {
    width: 100%;
    display: inline-block;
    vertical-align: top; }
  .DetailedContract .DetailedContract__row {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .DetailedContract .DetailedContract__table {
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: none;
    border-radius: 0.28571429rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    border-collapse: separate;
    border-spacing: 0;
    font-size: 1em; }
    .DetailedContract .DetailedContract__table thead tr:first-child > th:first-child {
      border-radius: 0.28571429rem 0 0 0; }
    .DetailedContract .DetailedContract__table thead {
      box-shadow: none;
      display: table-row-group; }
    .DetailedContract .DetailedContract__table thead th {
      cursor: auto;
      background: #f9fafb;
      text-align: inherit;
      color: rgba(0, 0, 0, 0.87);
      padding: 0.92857143em 0.78571429em;
      vertical-align: inherit;
      font-weight: 700;
      font-size: 14px;
      text-transform: none;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1); }
    .DetailedContract .DetailedContract__table tbody tr td {
      border-top: 1px solid rgba(34, 36, 38, 0.1);
      padding: 4px 8px;
      vertical-align: top; }
  .DetailedContract .signatures {
    width: 100%; }
    @media only screen and (min-width: 600px) {
      .DetailedContract .signatures {
        margin: auto;
        max-width: 290px; } }
  @media print {
    .DetailedContract .DetailedContract {
      border: 0;
      -webkit-print-color-adjust: exact; }
      .DetailedContract .DetailedContract table {
        page-break-after: auto; }
      .DetailedContract .DetailedContract tr {
        page-break-inside: avoid;
        page-break-after: auto; }
      .DetailedContract .DetailedContract td {
        page-break-inside: avoid;
        page-break-after: auto; }
      .DetailedContract .DetailedContract thead {
        display: table-header-group; }
      .DetailedContract .DetailedContract tfoot {
        display: table-footer-group; } }

.SummaryCO {
  border: 1px solid #b3b3b3;
  width: 1120px; }
  .SummaryCO body {
    -webkit-print-color-adjust: exact; }
  .SummaryCO .exclusions p {
    margin-bottom: 2px;
    width: 98%; }
  .SummaryCO .message .header {
    text-align: center; }

.contractSignatureCanvas {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  width: 100%;
  min-height: 200px; }

@media all {
  .SummaryCO {
    border: 0; }
    .SummaryCO body {
      -webkit-print-color-adjust: exact; } }

.DetailedCONew {
  padding: 34px;
  font-size: 11px; }
  .DetailedCONew .col-10 {
    width: 10%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .col-20 {
    width: 20%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .col-30 {
    width: 30%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .col-40 {
    width: 40%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .col-50 {
    width: 50%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .col-60 {
    width: 60%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .col-70 {
    width: 70%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .col-80 {
    width: 80%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .col-90 {
    width: 90%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .col-100 {
    width: 100%;
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .DetailedCONew__row {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .DetailedCONew .DetailedCONew__col--totals {
    display: inline-block;
    vertical-align: top; }
  .DetailedCONew .DetailedCONew__table {
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: none;
    border-radius: 0.28571429rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    border-collapse: separate;
    border-spacing: 0;
    font-size: 1em; }
    .DetailedCONew .DetailedCONew__table thead tr:first-child > th:first-child {
      border-radius: 0.28571429rem 0 0 0; }
    .DetailedCONew .DetailedCONew__table thead {
      box-shadow: none;
      display: table-row-group; }
    .DetailedCONew .DetailedCONew__table thead th {
      cursor: auto;
      background: #f9fafb;
      text-align: inherit;
      color: rgba(0, 0, 0, 0.87);
      padding: 0.92857143em 0.78571429em;
      vertical-align: inherit;
      font-weight: 700;
      font-size: 14px;
      text-transform: none;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1); }
    .DetailedCONew .DetailedCONew__table tbody tr td {
      border-top: 1px solid rgba(34, 36, 38, 0.1);
      padding: 4px 8px;
      vertical-align: top; }
  @media print {
    .DetailedCONew .DetailedCONew {
      border: 0;
      -webkit-print-color-adjust: exact; }
      .DetailedCONew .DetailedCONew table {
        page-break-after: auto; }
      .DetailedCONew .DetailedCONew tr {
        page-break-inside: avoid;
        page-break-after: auto; }
      .DetailedCONew .DetailedCONew td {
        page-break-inside: avoid;
        page-break-after: auto; }
      .DetailedCONew .DetailedCONew thead {
        display: table-header-group; }
      .DetailedCONew .DetailedCONew tfoot {
        display: table-footer-group; }
    .DetailedCONew .DetailedCONew__col--totals {
      width: 100%;
      display: inline-block;
      vertical-align: top; } }

@media print {
  .ContractToolbar {
    display: none !important; } }


.Survey {
  background-color: #ffffff;
  min-height: 100vh;
  height: 100%; }



.IncentivePayoutCertificate {
  margin: 0;
  padding: 0;
  color: white; }
  .IncentivePayoutCertificate .cer {
    position: relative;
    width: 11in;
    height: 8.2in;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    text-align: center; }
    .IncentivePayoutCertificate .cer .bonus-certificate-image {
      margin-left: 0.7in;
      margin-right: 0;
      margin-top: 0.3in;
      margin-bottom: 0.455in;
      width: 9.56in; }
  .IncentivePayoutCertificate td {
    text-align: center;
    color: black;
    border: 1px solid gray; }
  .IncentivePayoutCertificate th {
    text-align: center;
    color: black;
    font-weight: bold;
    border: 1px solid gray; }
  @media print {
    .IncentivePayoutCertificate .cer {
      page-break-after: always; } }

.Home {
  position: relative;
  width: 100%;
  background-color: #222; }

.ProjectList__Menu--Item:last-of-type {
  margin-bottom: 60px; }

.JobStartup .job-startup-signature-canvas {
  padding: 0;
  display: block;
  border: 2px dashed orange; }

.ProjectDispatch:last-of-type {
  margin-bottom: 60px; }

.TeamMemberDispatch:last-of-type {
  margin-bottom: 60px; }

.client-contract-container {
  position: relative;
  width: 1127px;
  padding: 34px; }
  .client-contract-container .clarifications-exclusions {
    padding: 2rem 0; }
    .client-contract-container .clarifications-exclusions .message {
      background-color: lightyellow; }
  .client-contract-container .contact {
    font-size: 11px;
    line-height: 1.4285em; }
  .client-contract-container .contract-requirements .concent-of-owner {
    padding: 2rem 0 1rem;
    font-size: 10px;
    text-align: justify; }
  .client-contract-container .contract-requirements .requirements {
    font-size: 11px; }
  .client-contract-container .heading {
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between; }
    .client-contract-container .heading > div:first-child, .client-contract-container .heading > div:last-child {
      width: 30%; }
    .client-contract-container .heading > div:last-child {
      text-align: right; }
  .client-contract-container .inclusions .category {
    font-size: 12px;
    font-weight: bold;
    background-color: lightyellow !important; }
  .client-contract-container .inclusions .sub-category th {
    color: gray !important;
    font-weight: bold !important; }
  .client-contract-container .inclusions .inclusion .total {
    color: #fe5000;
    font-weight: bold;
    text-align: right; }
  .client-contract-container .inclusions .footer th {
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    background-color: white !important;
    padding-top: 40px !important;
    padding-bottom: 10px !important;
    padding-right: 7.2px !important;
    padding-left: 7.2px !important;
    font-size: 22px !important;
    font-weight: bold; }
  .client-contract-container .inclusions .footer .total {
    color: #fe5000;
    font-weight: bold;
    text-align: right; }
  .client-contract-container .inclusions .category td,
  .client-contract-container .inclusions .category th,
  .client-contract-container .inclusions .sub-category td,
  .client-contract-container .inclusions .sub-category th,
  .client-contract-container .inclusions .inclusion td,
  .client-contract-container .inclusions .inclusion th {
    padding: 4px 8px;
    box-shadow: none;
    font-size: 12px;
    vertical-align: top; }
  .client-contract-container .project-summary {
    font-size: 11px;
    line-height: 1.4285em;
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .client-contract-container .project-summary .summary {
      max-width: 340px; }
    .client-contract-container .project-summary .title {
      align-items: center;
      text-align: right;
      margin: 0; }
  .client-contract-container .signature-modal p.error {
    color: red;
    font-size: 11px; }
  .client-contract-container .signatures {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 0; }
    .client-contract-container .signatures p.error {
      color: red;
      font-size: 12px;
      margin-top: 0.5rem !important; }
    .client-contract-container .signatures .signature {
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
      background-color: rgba(0, 0, 0, 0.05); }
      .client-contract-container .signatures .signature img {
        width: 100%; }
    .client-contract-container .signatures h3 {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      padding: 7px 0;
      margin: 0;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1); }
    .client-contract-container .signatures h5 {
      font-size: "1.28571429em";
      padding: 8px 10px; }
      .client-contract-container .signatures h5 small {
        display: block;
        page-break-inside: avoid;
        -webkit-column-break-inside: avoid;
                break-inside: avoid;
        color: rgba(0, 0, 0, 0.4); }
    .client-contract-container .signatures .client,
    .client-contract-container .signatures .nexus {
      width: 290px;
      page-break-inside: avoid;
      -webkit-column-break-inside: avoid;
              break-inside: avoid;
      border-radius: 5px;
      border: 1px solid rgba(34, 36, 38, 0.1); }
  .client-contract-container .totals-summary {
    font-size: 11px; }
    .client-contract-container .totals-summary th:first-child {
      width: 75%; }
    .client-contract-container .totals-summary th {
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      background-color: rgba(0, 0, 0, 0.03); }
    .client-contract-container .totals-summary tr:last-child th {
      border-bottom: none; }
    .client-contract-container .totals-summary td {
      font-weight: 700; }
      .client-contract-container .totals-summary td.price {
        color: #fe5000; }
    .client-contract-container .totals-summary .change-order-summary thead th:first-child {
      border-top: none !important;
      border-left: none !important;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      background-color: rgba(0, 0, 0, 0.03); }
    .client-contract-container .totals-summary .change-order-summary thead th:last-child {
      border-left: 1px solid rgba(34, 36, 38, 0.15); }
    .client-contract-container .totals-summary .change-order-summary tbody tr:first-child > td {
      border-top: 1px solid rgba(34, 36, 38, 0.15); }

.signature-modal p.error {
  color: red;
  font-size: 11px; }

