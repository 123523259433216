.client-contract-container {
  position: relative;
  width: 1127px;
  padding: 34px;

  @import "./components/clarifications-exclusions.scss";
  @import "./components/contact.scss";
  @import "./components/contract-requirements.scss";
  @import "./components/heading.scss";
  @import "./components/inclusions.scss";
  @import "./components/project-summary.scss";
  @import "./components/signature-modal.scss";
  @import "./components/signatures";
  @import "./components/totals-summary.scss";
}
