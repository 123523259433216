.SummaryContract {
  border: 1px solid rgb(179, 179, 179);
  width: 1120px;
  body {
    -webkit-print-color-adjust: exact;
  }

  .exclusions p {
    margin-bottom: 2px;
    width: 98%;
  }

  .message {
    .header {
      text-align: center;
    }
  }
}

.contractSignatureCanvas {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  width: 100%;
  min-height: 200px;
}

@media all {
  .SummaryContract {
    border: 0;
    body {
      -webkit-print-color-adjust: exact;
    }
  }
}
