.InclusionTable {
  .border-left {
    border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
  }
  .footer-text {
    color: blue !important;
    font-size: 12px;
    font-weight: bold !important;
  }
}
