.MonthInput {
  &:hover {
    cursor: pointer;
  }
}

.input__forecast {
  width: 30px;
  height: 21px;
}
