.project-summary {
  font-size: 11px;
  line-height: 1.4285em;
  padding: 2rem 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .summary {
    max-width: 340px;
  }

  .title {
    align-items: center;
    text-align: right;
    margin: 0;
  }
}
